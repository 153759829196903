import React from "react";
import { navigate } from "gatsby";

import styled from "src/theme/styled";
import Text from "src/components/text";
import Icon from "src/components/icon";
import Link from "src/components/link";

import { FullWidthColumnWrapper } from "../confines-wrappers";

export interface IVacancy {
  id: string;
  created: string;
  changed: string;
  title: string;
  gatsbyPath: string;
  field_job_advert_summary?: {
    processed: string;
  };
  field_job_advert_copy: {
    processed: string;
  };
}

interface VacanciesListProps {
  vacancies?: IVacancy[];
  innerRef?: React.RefObject<HTMLDivElement>;
}

export const VacanciesList: React.FC<VacanciesListProps> = ({ vacancies, innerRef }) => {
  return (
    <VacanciesWrapper innerRef={innerRef}>
      <FullWidthColumnWrapper>
        <SectionTitle>
          Current opportunities at Gravitywell
        </SectionTitle>

        {vacancies ? (
          vacancies.map(
            ({
              id, title, gatsbyPath
            }) => (
              <VacancyWrapper
                key={id}
                onClick={() => navigate(gatsbyPath)}
              >
                <Text fontWeight="bold">
                  {title}
                </Text>

                <Icon icon="arrowRight" />
              </VacancyWrapper>
            )
          )
        ) : (
          <Text fontSize="subhead_1">
            There are currently no positions available.
          </Text>
        )}

        <ContactText fontSize="subhead_2">
          {"We look for thoughtful, skilful people to work with long-term. If you’re interested in joining the team, please "}

          <Link href="mailto:careers@gravitywell.co.uk">
            send us a message
          </Link>
          .
        </ContactText>
      </FullWidthColumnWrapper>
    </VacanciesWrapper>
  );
};

const SectionTitle = styled(Text).attrs({ fontWeight: "heavy" })`
  margin-bottom: 1rem;

  font-size: ${({ theme }) => theme.font.size.subhead_2};
  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.font.size.subhead_3};
  }
`;

const VacanciesWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.lightestGrey};
  
  margin-top: 6rem;
  padding: 6rem 0;
  @media (min-width: 768px) {
    margin-top: 12rem;
    padding: 12rem 0;
  }
  
  & > div {
    display: flex;
    flex-direction: column;
  }

  ${SectionTitle} {
    margin-bottom: 4rem;
    @media (min-width: 768px) {
      margin-bottom: 8rem;
    }
    
  }
`;

const VacancyWrapper = styled.div`
  padding: 3rem;
  padding-left: 0;
  border-bottom: 2px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span, svg {
    transition: 0.25s all ease;
  }

  &:hover {
    cursor: pointer;
    span {
      color: ${({ theme }) => theme.color.brandColor};
    }
    svg {
      fill: ${({ theme }) => theme.color.brandColor};
    }
  }
`;

const ContactText = styled(Text)`
  max-width: 82rem;

  margin-top: 8rem;
  margin-bottom: 4rem;
  font-size: ${({ theme }) => theme.font.size.subhead_1};

  @media (min-width: 768px) {
    margin-top: 12rem;
    margin-bottom: 8rem;
    font-size: ${({ theme }) => theme.font.size.subhead_2};
  }
`;