import React from "react";
import {
  motion,
  AnimatePresence
} from "framer-motion";

import styled from "src/theme/styled";

interface ModalProps {
  visible: boolean;
  onClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  visible,
  onClose,
  children
}) => {
  React.useEffect(() => {
    if (visible) {
      document.querySelector("body")?.classList.add("noscroll");
    } else {
      document.querySelector("body")?.classList.remove("noscroll");
    }
  }, [ visible ]);

  return (
    <AnimatePresence>
      {visible && (
        <ModalWrapper
          onClick={onClose}
          initial={{
            opacity: 0,
            pointerEvents: "none"
          }}
          animate={{
            opacity: 1,
            pointerEvents: "auto"
          }}
          exit={{
            opacity: 0,
            pointerEvents: "none"
          }}
        >
          {children}
        </ModalWrapper>
      )}
    </AnimatePresence>
  );
};

const ModalWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000088;
  z-index: 1000;
`;