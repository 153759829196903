import React from "react";

import styled from "src/theme/styled";
import Text from "src/components/text";

interface CareerPerkProps {
  Icon: React.FC;
  description: string;
}

export const CareerPerk: React.FC<CareerPerkProps> = ({
  Icon,
  description
}) => (
  <PerkWrapper>
    <Icon />

    <Text>
      {description}
    </Text>
  </PerkWrapper>
);

const PerkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  &:not(:first-child) {
    margin-top: 2rem;
  }

  svg {
    height: 24px;
    min-width: 24px;
    fill: ${({ theme }) => theme.color.brandColor};
    margin-right: 3rem;
  }

  svg#pram {
    fill: none;
    stroke-width: 1.6px;
    stroke: ${({ theme }) => theme.color.brandColor};
  }
`;