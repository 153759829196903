import * as React from "react";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontSpaceHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import generator from "../gradients/generator";
import Link from "../link";
import { linkCSS } from "../link/link";

type CtaTypes = "primary";

interface IProps {
  className?: string;
  type: CtaTypes;
  href?: string;
  text?: string;
  target?: string;
  onClick?: () => void;
}

interface ICtaButton {
  type: CtaTypes;
}

/**
 * Returns a cta button
 */
export const CtaButton: React.FC<IProps> = ({
  className,
  type,
  href,
  text,
  target,
  onClick,
  children
}) => {
  target = target || "_self";

  return (
    <CtaButtonContainer
      className={[ `cta-button cta-button-${type}`, className ].join(" ")}
      type={type}
      onClick={onClick}
    >
      {href ? (
        <Link
          type="none"
          href={href}
          target={target}
        >
          {text ? (
            <span>
              {text}
            </span>
          ) : (
            children
          )}
        </Link>
      ) : (
        <LinkContent>
          {text ? (
            <span>
              {text}
            </span>
          ) : (
            children
          )}
        </LinkContent>
      )}
    </CtaButtonContainer>
  );
};

const LinkContent = styled.div`
  ${linkCSS}
  border-bottom: none;
  &:hover {
    cursor: pointer;
  }
`;

const CtaButtonContainer = styled<ICtaButton, "p">("p")`
  display: inline-block;
  padding: 0;

  & > * {
    display: block;
    min-width: 100%;
    min-height: 100%;
    padding: 0.8rem 1.4rem;
    color: ${({ theme }) => colourHelper({
    theme,
    variant: "white"
  })};
    font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "small_2"
  })};
    font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
    line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};
    letter-spacing: ${({ theme }) =>
    fontSpaceHelper({
      theme,
      variant: "medium"
    })};
    text-transform: uppercase;
    ${props =>
    props.type === "primary" &&
      generator({
        gradient: "orangePink",
        angle: -38
      })};
    background-size: 150% 150%;
    background-position: 100% 0;

    &:hover {
      background-position: 0% 100%;
    }
  }

  @media (min-width: 600px) {
    & > * {
      padding: 1rem 1.8rem;
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
    }
  }
`;

export default CtaButton;
