import { graphql } from "gatsby";
import React from "react";
import { useWindowDimensions } from "@gravitywelluk/react-hooks";

import styled, { css } from "src/theme/styled";
import {
  IFluid,
  ILocalFile
} from "src/types/local-files";
import HeroVideo from "src/components/hero-video";
import Text from "src/components/text";
import { CtaButton } from "src/components/cta";
import {
  IVacancy,
  VacanciesList
} from "src/components/careers/VacanciesList";
import { FullWidthScrollingGallery } from "src/components/scrolling-gallery";
import { QuoteBlock } from "src/components/quote-block";
import { Modal } from "src/components/modal";
import VideoPanel from "src/components/video-panel";
import Link from "src/components/link";
import { Quote } from "src/components/quote";
import { CareerPerk } from "src/components/career-perk";
import DynamicImage from "src/components/dynamic-image";
import Icon from "src/components/icon";
import QuoteImage1 from "src/assets/images/careers/quote-1.jpg";
import QuoteImage2 from "src/assets/images/careers/quote-2.jpg";
import {
  LaptopIcon,
  BeachIcon,
  ClockIcon,
  PramIcon,
  KeyboardIcon,
  SmileysIcon,
  HeartIcon,
  GiftIcon,
  MoonIcon
} from "src/assets/images/careers/icons";
import {
  FullWidthColumnWrapper,
  HalfColumnWidthWrapper
} from "src/components/confines-wrappers";
import Footer from "src/components/footer";
import Header from "src/components/header";
import HelmetWrapper from "src/components/helmet-wrapper";
import { breadCrumbSchema } from "src/components/html-schema-snippets";
import Layout from "src/components/layout";
import PageTop from "src/components/page-top";
import { IBreadCrumbSchema } from "src/components/html-schema-snippets/breadcrumb-schema";
import {
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "src/theme/theme-helper";

export interface CareersArticle {
  title: string;
  field_meta_description: string;
  changed: string;
  field_hiring: boolean;
  field_hero_video_url: string | null;
  relationships: {
    field_culture_video?: {
      field_video_caption: string | null;
      field_video_url: string | null;
      relationships: {
        field_video_thumbnail: ILocalFile<IFluid> | null;
      };
    };
    field_gallery: IGalleryImage[];
    field_careers_ordered_vacancies?: IVacancy[];
  };
}

interface IProps {
  data: {
    nodeCareersOverview: CareersArticle;
  };
}

export interface IGalleryImage {
  id: string;
  relationships: {
    field_image: ILocalFile<IFluid>;
  };
}

interface IPerk {
  id: string;
  field_perk: string;
  relationships: {
    field_image: {
      localFile: {
        name: string;
        publicURL: string;
      };
    };
  };
}

/**
 * Careers
 */
const Careers: React.FC<IProps> = ({ data }) => {
  const article = data.nodeCareersOverview;
  const vacanciesRef = React.useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();
  const extentsWidth = React.useMemo(() => Math.min(typeof screen !== "undefined" ? screen.width : 1280, width || 1280), [ width ]);
  // set header text to white if background video present
  const highContrastHeader = extentsWidth > 768 && !!data.nodeCareersOverview.field_hero_video_url;
  const [ videoModalVisible, setVideoModalVisible ] = React.useState(false);

  const scrollToOpportunities = () => {
    vacanciesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Careers",
      path: "/careers"
    }
  ]);

  const schemaJson: IBreadCrumbSchema[] = [ breadcrumbsSchemaData ];

  return (
    <Layout>
      <>
        <HelmetWrapper
          title={article.title}
          description={article.field_meta_description}
          updatedAt={article.changed}
          canonicalPath="/careers"
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header
          role="banner"
          id="hero"
        >
          <Header
            initialLogo
            initialTheme={highContrastHeader ? "dark" : "light"}
          />

          <PageTop
            gradient="lightPinkPurple"
            height="small"
          >

            {extentsWidth > 768 && article.field_hero_video_url && (
              <HeroVideo
                src={article.field_hero_video_url}
                contrastGradient
                fallbackImage=""
              />
            )}

            <PageTopContentWrapper className="top-content">

              {article.field_hiring && (
                <HiringWrapper>
                  <HiringText
                    fontColor="secondary"
                    fontWeight="bold"
                    letterSpacing="medium"
                  >
                    We're hiring
                  </HiringText>

                  <PartyEmoji fill={highContrastHeader ? "white" : "black"} />
                </HiringWrapper>
              )}

              <PageTitle fontColor={highContrastHeader ? "third" : "primary"}>
                Come and join us
              </PageTitle>

              <Text
                fontWeight="light"
                fontColor={highContrastHeader ? "third" : "primary"}
              >
                Collaborate, innovate &amp; create.
              </Text>

              {article.field_hiring && article.relationships.field_careers_ordered_vacancies && (
                <CtaWrapper>
                  <CtaButton
                    type="primary"
                    onClick={scrollToOpportunities}
                  >
                    <ButtonText>
                      See opportunities
                    </ButtonText>
                  </CtaButton>
                </CtaWrapper>
              )}

            </PageTopContentWrapper>
          </PageTop>
        </header>

        <Main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            {article.title}
          </h1>

          <CultureBlock>
            <CultureBody>
              <SectionTitle>
                What’s it like at Gravitywell?
              </SectionTitle>

              <CultureText>
                Our culture is our company. The Gravitywell team is full of very smart, talented individuals with a genuine passion for new ideas and delivering the best possible products.
              </CultureText>

              <CultureText>
                <Link
                  type="inline"
                  href="/projects"
                >
                  Projects
                </Link>

                {" aren’t restricted by sector — if an idea is breaking new ground, we want to be immersed from conception to MVP and well beyond."}
              </CultureText>

              <CultureText>
                One minute you could be working on a Machine Learning project for a well known charity or adding a module to our very own SaaS startup, the next minute you could be researching new technologies on a team Hackathon in the south of Spain.
              </CultureText>

              <CultureText>
                Gravitywell is a flat hierarchy in the truest sense of the phrase. Individual input isn’t just encouraged, it’s an essential ingredient to our success.
              </CultureText>
            </CultureBody>

            {article.relationships.field_culture_video?.field_video_url &&
            article.relationships.field_culture_video?.field_video_caption && (
              <VideoColumn>
                <DynamicImage data={article.relationships.field_culture_video.relationships.field_video_thumbnail} />

                <PlayVideoButton
                  type="primary"
                  href="#"
                  onClick={() => setVideoModalVisible(true)}
                >
                  <Icon
                    icon="play"
                    color="white"
                  />

                  <ButtonText>
                    {article.relationships.field_culture_video.field_video_caption}
                  </ButtonText>
                </PlayVideoButton>
              </VideoColumn>
            )}

          </CultureBlock>

          <GalleryWrapper width={extentsWidth}>
            <FullWidthScrollingGallery images={article.relationships.field_gallery} />
          </GalleryWrapper>

          <TeamQuotesWrapper>
            <QuoteBlock>
              <Quote
                quote="If you’re looking for autonomy, you’re in the right place. There’s no set stack at Gravitywell because we start every project from scratch, which means we can utilise new and innovative tech to design the best architecture possible."
                authorName="Luke Baker"
                authorRole="Head of Architecture &amp; Security"
                quoteImage={QuoteImage1}
              />

              <Quote
                quote="At Gravitywell, we’re involved with projects from conception through to release. We take a holistic approach to brand strategy and design, with the focus on a meaningful user experience."
                authorName="Matt Boyle"
                authorRole="Lead Designer"
                quoteImage={QuoteImage2}
              />
            </QuoteBlock>
          </TeamQuotesWrapper>

          <PerksWrapper>
            <HalfColumnWidthWrapper>
              <SectionTitle>
                We’re a close-knit, locally distributed team that knows how to balance work and play
              </SectionTitle>

              <PerksList>
                <CareerPerk
                  Icon={LaptopIcon}
                  description="Remote friendly - from home or chosen workplace"
                />

                <CareerPerk
                  Icon={BeachIcon}
                  description="Annual Hackathon Retreat"
                />

                <CareerPerk
                  Icon={ClockIcon}
                  description="Flexible working arrangements"
                />

                <CareerPerk
                  Icon={PramIcon}
                  description="Parental leave and support"
                />

                <CareerPerk
                  Icon={KeyboardIcon}
                  description="The best tools and equipment you need to do your job"
                />

                <CareerPerk
                  Icon={SmileysIcon}
                  description="Regular meet-ups"
                />

                <CareerPerk
                  Icon={HeartIcon}
                  description="Self-development support"
                />

                <CareerPerk
                  Icon={GiftIcon}
                  description="2 week Christmas shutdown"
                />

                <CareerPerk
                  Icon={MoonIcon}
                  description="Overtime is a no-no"
                />
              </PerksList>
            </HalfColumnWidthWrapper>
          </PerksWrapper>

          <VacanciesList
            vacancies={article.relationships.field_careers_ordered_vacancies}
            innerRef={vacanciesRef}
          />

          {article.relationships.field_culture_video?.field_video_url && (
            <Modal
              visible={videoModalVisible}
              onClose={() => setVideoModalVisible(false)}
            >
              <VideoAspectWrapper>
                <VideoContainer onClick={e => e.stopPropagation()}>
                  <StyledVideoPanel
                    url={article.relationships.field_culture_video.field_video_url}
                    fallbackImage=""
                  />
                </VideoContainer>
              </VideoAspectWrapper>
            </Modal>
          )}
        </Main>

        <footer role="contentinfo">
          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default Careers;

export const query = graphql`
  query careersQuery {
    nodeCareersOverview {
      title
      changed
      field_hiring
      field_hero_video_url
      relationships {
        field_culture_video {
          field_video_caption
          field_video_url
          relationships {
            field_video_thumbnail {
              localFile {
                publicURL
                childImageSharp {
                  fluid(
                    quality: 80
                    maxWidth: 640
                    srcSetBreakpoints: [320, 480]
                  ) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
        field_gallery {
          id
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid(
                    quality: 80
                    maxWidth: 1280
                    srcSetBreakpoints: [768, 1280]
                  ) {
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
            }
          }
        }
        field_careers_ordered_vacancies {
          created
          id
          title
          gatsbyPath(filePath: "/careers/{NodeCareersVacancy.title}")
        }
      }
    }
  }
`;

const PageTopContentWrapper = styled(FullWidthColumnWrapper)`
  z-index: 5;
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

const HiringWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;

  i {
    margin-top: -0.5rem;
  }
`;

const HiringText = styled(Text)`
  text-transform: uppercase;
  margin-right: 2rem;
`;

interface PartyEmojiProps {
  fill: string;
}

const PartyEmoji: React.FC<PartyEmojiProps> = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="30"
    viewBox="0 0 40 37.779"
    fill={fill}
  >
    <g transform="translate(-2.678 -16.274)">
      <path
        d="M5.365,41.16l1.89-.994,1.89.994a.867.867,0,0,0,1.258-.914l-.361-2.1,1.529-1.49a.867.867,0,0,0-.481-1.478l-2.113-.307-.945-1.915a.867.867,0,0,0-1.554,0l-.945,1.915-2.113.307a.867.867,0,0,0-.481,1.478l1.529,1.49-.361,2.1a.867.867,0,0,0,1.258.914Zm.868-4.644a.867.867,0,0,0,.653-.474l.369-.749.369.749a.867.867,0,0,0,.653.474l.826.12-.6.583a.867.867,0,0,0-.249.767l.141.823-.739-.389a.867.867,0,0,0-.807,0l-.739.388.141-.823A.867.867,0,0,0,6,37.219l-.6-.583Z"
        transform="translate(0 -14.918)"
      />

      <path
        d="M409.015,18.778l-1.922-.279-.859-1.741a.867.867,0,0,0-1.555,0L403.82,18.5l-1.922.279a.867.867,0,0,0-.481,1.478l1.391,1.355-.328,1.914a.867.867,0,0,0,1.258.914l1.719-.9,1.72.9a.867.867,0,0,0,1.258-.914l-.328-1.914,1.391-1.355a.867.867,0,0,0-.481-1.478Zm-2.446,1.911a.867.867,0,0,0-.249.767l.108.632-.568-.3a.866.866,0,0,0-.807,0l-.568.3.108-.632a.867.867,0,0,0-.249-.767l-.459-.448.635-.092a.867.867,0,0,0,.653-.474l.284-.575.284.575a.867.867,0,0,0,.653.474l.635.092Z"
        transform="translate(-367.08)"
      />

      <path
        d="M323.789,356.276l-3.016-.438-1.348-2.733a.867.867,0,0,0-1.555,0l-1.349,2.733-3.016.438a.867.867,0,0,0-.481,1.478l2.182,2.127-.515,3a.867.867,0,0,0,1.258.914l2.7-1.418,2.7,1.418a.867.867,0,0,0,1.258-.914l-.515-3,2.182-2.127a.867.867,0,0,0-.481-1.478Zm-3.238,2.682a.867.867,0,0,0-.249.767l.3,1.721-1.546-.813a.866.866,0,0,0-.807,0l-1.546.813.3-1.721a.867.867,0,0,0-.249-.767l-1.251-1.219,1.729-.251a.867.867,0,0,0,.653-.474l.773-1.566.773,1.566a.867.867,0,0,0,.653.474l1.728.251Z"
        transform="translate(-285.653 -309.845)"
      />

      <path
        d="M52.335,71.7a1.6,1.6,0,0,0,1.184-.457c.863-.863.51-2.431-1.11-4.935a34.369,34.369,0,0,0-2.5-3.333,3.754,3.754,0,0,1,4.266.73A.867.867,0,1,0,55.4,62.478a5.491,5.491,0,0,0-6.66-.847q-.7-.777-1.461-1.559l5.806-5.806a.867.867,0,0,0-1.226-1.226l-5.807,5.807q-.717-.695-1.433-1.342l.62-.62a5.488,5.488,0,0,0,0-7.752.867.867,0,1,0-1.226,1.226,3.752,3.752,0,0,1,0,5.3l-.7.7A34.631,34.631,0,0,0,39.9,53.8c-2.5-1.62-4.072-1.973-4.934-1.11a1.522,1.522,0,0,0-.23.295l-.013,0-.024.069a1.567,1.567,0,0,0-.154.443L25.437,79.665a.867.867,0,0,0,1.106,1.1L52.332,71.7Zm-10.253-14.1-.587.587a.867.867,0,1,0,1.226,1.226l.673-.673q.716.644,1.435,1.34l-.871.871a.867.867,0,0,0,1.226,1.226l.872-.872c.463.477.912.955,1.343,1.432l-.65.65a.867.867,0,1,0,1.226,1.226l.566-.566a34.849,34.849,0,0,1,2.121,2.769A8.946,8.946,0,0,1,52.228,69.9l-.12.042a9.462,9.462,0,0,1-3.022-1.555A44.617,44.617,0,0,1,43,63.208a44.612,44.612,0,0,1-5.176-6.089,8.422,8.422,0,0,1-1.575-3.15,8.422,8.422,0,0,1,3.15,1.575C40.238,56.116,41.146,56.811,42.082,57.594Zm-10.23,16.75c-.626-.933-1.215-1.871-1.757-2.794l1.456-4.185c.745,1.37,1.618,2.805,2.575,4.231.877,1.307,1.8,2.571,2.717,3.711l-3.453,1.214C32.869,75.823,32.353,75.092,31.852,74.344Zm3.714-3.714a48.916,48.916,0,0,1-3.231-5.519l1.174-3.375a47.91,47.91,0,0,0,3.8,6.676,50.921,50.921,0,0,0,4.057,5.3l-2.787.98C37.574,73.477,36.539,72.082,35.565,70.631Zm-6.21,3.046q.508.816,1.057,1.633c.415.618.839,1.225,1.267,1.813l-4.014,1.412Zm13.786-.584a47.255,47.255,0,0,1-4.392-5.645,40.009,40.009,0,0,1-4.377-8.192l.985-2.833q.3.56.717,1.2a44.3,44.3,0,0,0,5.7,6.813,44.285,44.285,0,0,0,6.813,5.7q.582.377,1.1.661Z"
        transform="translate(-20.922 -30.037)"
      />
    </g>
  </svg>
);

const CtaWrapper = styled.div`
  margin-top: 4rem;
  @media (min-width: 768px) {
    margin-top: 8rem;
  }

  & > * > * {
    border-radius: 4px;
    display: flex!important;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding: 1.5rem 2rem!important;
    
    @media (min-width: 768px) {
      padding: 2rem 3rem!important;
    }
  }
`;

const ButtonText = styled(Text).attrs({
  fontColor: "third",
  letterSpacing: "medium",
  fontSize: "small_3"
})`
  text-transform: uppercase;
`;

const PageTitle = styled(Text)`
${({ theme }) => css`
  transition: 0.2s ease-in-out;
  display: block;
  padding-top: 0;
  margin-bottom: 3rem;
  font-size: ${fontSizeHelper({
    theme,
    variant: "subhead_2"
  })};
  font-weight: ${fontWeightHelper({
    theme,
    variant: "heavy"
  })};
  line-height: ${fontLineHeightHelper({
    theme,
    variant: "default"
  })};

@media (min-width: 768px) {
  max-width: 66rem;
  font-size: ${fontSizeHelper({
    theme,
    variant: "display_2"
  })};
  line-height: ${fontLineHeightHelper({
    theme,
    variant: "small"
  })};
}

@media (min-width: 960px) {
  max-width: 76rem;
  font-size: ${fontSizeHelper({
    theme,
    variant: "display_3"
  })};
}

@media (min-width: 1024px) {
  max-width: 84rem;
  font-size: ${fontSizeHelper({
    theme,
    variant: "display_4"
  })};
}
`}
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  @media (min-width: 768px) {
    margin-top: 6rem;
  }
`;

const CultureBlock = styled(FullWidthColumnWrapper)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  margin: 4rem auto;
  @media (min-width: 768px) {
    margin: 8rem auto;
  }
`;

const CultureBody = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-basis: 50%;
  }
`;

const CultureText = styled(Text)`
  margin-top: 2rem;
`;

const VideoColumn = styled(HalfColumnWidthWrapper)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: 5rem;
  flex-basis: 100%;
  @media (min-width: 768px) {
    /* margin-top: 0; */
    flex-basis: 35%;
    margin-right: 0;
  }
`;

const PlayVideoButton = styled(CtaButton)`
  position: absolute;
  align-self: center;
  bottom: -2.6rem;
  
  & > * {
    display: flex!important;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding: 1.5rem 2rem!important;
  }

  @media (min-width: 768px) {
    bottom: -3.2rem;
    & > * {
      padding: 2rem 3rem!important;
    }
  }

  i {
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }
`;

const GalleryWrapper = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  overflow: hidden;
  margin: 4rem 0;
  @media (min-width: 768px) {
    margin: 8rem 0;
  }
`;

const SectionTitle = styled(Text).attrs({ fontWeight: "heavy" })`
  margin-bottom: 1rem;

  font-size: ${({ theme }) => theme.font.size.subhead_2};
  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.font.size.subhead_3};
  }
`;

const TeamQuotesWrapper = styled.div`
  width: 100%;
  margin: 4rem auto;
  @media (min-width: 768px) {
    margin: 8rem auto;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PerksWrapper = styled(FullWidthColumnWrapper)`
  margin: 4rem auto;
  @media (min-width: 768px) {
    margin: 8rem auto;
  }

  & > div {
    margin-left: 0;
  }
`;

const PerksList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4rem 0;
`;

const VideoAspectWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  @media (min-width: 768px) {
    width: 80%;
    padding-top: 45%;
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledVideoPanel = styled(VideoPanel)`
  width: 100%;
  height: 100%;
`;