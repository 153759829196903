import { useWindowDimensions } from "@gravitywelluk/react-hooks";
import React from "react";
import Slider from "react-slick";

import styled from "src/theme/styled";
import DynamicImage from "src/components/dynamic-image";
import {
  IFluid,
  ILocalFile
} from "src/types/local-files";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export interface IGalleryImage {
  id: string;
  relationships: {
    field_image: ILocalFile<IFluid>;
  };
}

interface FullWidthScrollingGalleryProps {
  images: IGalleryImage[];
}

export const FullWidthScrollingGallery: React.FC<FullWidthScrollingGalleryProps> = ({ images }) => {
  const { width } = useWindowDimensions();
  const extentsWidth = React.useMemo(() => Math.min(typeof screen !== "undefined" ? screen.width : 1280, width || 1280), [ width ]);
  // ensure the number of visible slides is 1 more than what fits in the viewport
  const numberOfSlides = React.useMemo(() => Math.ceil(extentsWidth / 340) + 1, [ extentsWidth ]);

  const galleryImages = React.useMemo(() => {
    // padding for smooth scroll
    const numberOfColumns = numberOfSlides + 1;
    // 2 images per column
    const requiredImages = numberOfColumns * 2;
    const filledGallery = [];

    if (images.length < requiredImages) {
      // ensure even number of images
      const truncated = images.length % 2 ? images.slice(0, -1) : images;

      // duplicate image set until there are enough to fill the visible columns
      while (filledGallery.length < requiredImages) {
        filledGallery.push(...truncated);
      }
    }

    return filledGallery;
  }, [ images, numberOfSlides ]);

  return (
    <GalleryBlock columns={numberOfSlides}>
      <Slider
        slidesToShow={numberOfSlides}
        slidesToScroll={1}
        pauseOnHover={false}
        rows={2}
        speed={10000}
        autoplaySpeed={0}
        cssEase="linear"
        infinite
        autoplay
      >
        {galleryImages.map((node, i) => (
          <GalleryImage
            key={`${i}-${node.id}`}
            data={node.relationships.field_image}
          />
        ))}
      </Slider>
    </GalleryBlock>
  );
};

const GalleryImage = styled(DynamicImage)`
  width: 30rem;
  height: 22.5rem;
  object-fit: cover;
  margin: 2rem;
`;

const GalleryBlock = styled.div<{ columns: number }>`
  position: relative;
  width: ${({ columns }) => columns * 340}px;
  margin: 0 auto;

  &:after {
    content: "";
    position: absolute;
    z-index: 10;
    top: 1rem;
    left: 0;
    right: 0;
    pointer-events: none;
    background-image: linear-gradient(to top, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%);
    height: 15rem;
  }

  .slick-slide {
    display: flex!important;
    flex-direction: column!important;
    align-items: center!important;

    &:nth-child(even) {
      margin-top: 4rem;

      div:first-child ${GalleryImage} {
        height: 40rem;
      }
    }
    
    &:nth-child(odd) div:last-child ${GalleryImage} {
      height: 40rem;
    }
  }
`;