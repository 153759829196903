import React from "react";
import Slider from "react-slick";

import styled from "src/theme/styled";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const QuoteBlock: React.FC = ({ children }) => {
  const [ activeIndex, setActiveIndex ] = React.useState(0);
  const sliderRef = React.useRef<Slider>(null);

  const onSlideClick = (i: number) => {
    setActiveIndex(i);
    sliderRef.current?.slickGoTo(i);
  };

  return (
    <Wrapper>
      <DotContainer>
        {React.Children.map(children, (_, i) => (
          <Dot
            key={i}
            active={i === activeIndex}
            onClick={() => onSlideClick(i)}
          />
        ))}
      </DotContainer>

      <Slider
        ref={sliderRef}
        slidesToShow={1}
        rows={1}
        centerMode
        centerPadding="0"
        afterChange={newIndex => setActiveIndex(newIndex)}
        infinite={false}
        dots={false}
        arrows={false}
      >
        {children}
      </Slider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 4rem;
  }
`;

const DotContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3rem;
  padding-top: 0;
  @media (min-width: 768px) {
    justify-content: flex-start;
    padding-top: 3rem;
    padding: 3rem 6rem;
  }
`;

const Dot = styled.div<{ active: boolean }>`
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 0.6rem;
  background-color: ${({ theme, active }) => active ? theme.color.lightGrey : theme.color.lightestGrey};
  transition: 0.5s all ease;

  &:hover {
    cursor: pointer;
  }

  &:first-child {
    margin-right: 1rem;
  }
`;