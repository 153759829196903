import React from "react";
import ReactPlayer from "react-player";

import BackgroundImage from "../../assets/images/video-grid-overlay.png";
import styled from "../../theme/styled";

interface IProps {
  url: string;
  fallbackImage: string;
  className?: string;
}

interface IWrapperProps {
  fallbackImage: string;
}

const PlayerWrapper = styled<IWrapperProps, "div">("div")`
  background-image: url(${props => props.fallbackImage});
  background-size: cover;
  background-position: center;

  .overlay-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: url(${BackgroundImage});
    background-repeat: repeat;
    opacity: 0.2;
  }

  video {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const VideoPanel: React.FC<IProps> = ({
  url,
  fallbackImage,
  className,
  children
}) => (
  <PlayerWrapper
    fallbackImage={fallbackImage}
    className={[ "player-inner", className ].join(" ")}
  >
    {children}

    <div className="overlay-img" />

    <ReactPlayer
      className="react-player"
      playing
      controls={false}
      width="100%"
      height="100%"
      muted
      volume={1}
      loop
      url={url}
    />
  </PlayerWrapper>
);

export default VideoPanel;
