import React from "react";

import styled from "src/theme/styled";
import Text from "src/components/text";

interface QuoteProps {
  quote: string;
  authorName: string;
  authorRole: string;
  quoteImage: string;
}

export const Quote: React.FC<QuoteProps> = ({
  quote,
  authorName,
  authorRole,
  quoteImage
}) => (
  <SlideContainer>
    <QuoteCard>
      <QuoteContainer>
        <QuoteText>
          {quote}
        </QuoteText>
      </QuoteContainer>

      <AuthorText>
        {authorName}
      </AuthorText>

      <RoleText>
        {authorRole}
      </RoleText>

      <QuoteImageContainer>
        <QuoteImage src={quoteImage} />
      </QuoteImageContainer>
    </QuoteCard>
  </SlideContainer>
);

const SlideContainer = styled.div`
  width: 100%;
  display: flex!important;
  justify-content: center;
  align-items: center;
  padding-top: 16rem;
  padding-left: 2rem;
  padding-right: 2rem;
  @media (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 14rem;
    padding-left: 0;
    padding-right: 0;
  }
`;

const QuoteCard = styled.div`
  background-color: ${({ theme }) => theme.color.lightestGrey};
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 8rem 2rem;
  padding-bottom: 4rem;
  @media (min-width: 768px) {
    padding: 5rem 6rem;
  }
  width: 92rem;
`;

const QuoteContainer = styled.div`
  padding: 1rem 2rem;
  margin-bottom: 3rem;
  @media (min-width: 768px) {
    padding: 1rem 3rem;
    margin-bottom: 6rem;
  }
  padding-bottom: 4rem!important;
  border-left: 2px solid ${({ theme }) => theme.color.brandColor};
`;

const QuoteText = styled(Text).attrs({ fontWeight: "heavy" })`
  font-style: italic;
`;

const AuthorText = styled(Text).attrs({
  fontWeight: "bold",
  fontSize: "small_3"
})``;

const RoleText = styled(Text).attrs({
  fontWeight: "light",
  letterSpacing: "medium",
  fontSize: "small_1"
})`
  text-transform: uppercase;
`;

const QuoteImageContainer = styled.div`
  position: absolute;
  align-self: center;
  top: -16rem;
  @media (min-width: 768px) {
    top: unset;
    bottom: -40%;
    right: 6rem;
  }
`;

const QuoteImage = styled.img`
  width: 30rem;
  @media (min-width: 768px) {
    width: 40rem;
  }
`;