import React, { CSSProperties } from "react";

import styled from "../../theme/styled";
import { generator } from "../gradients";
import { IGradients } from "../gradients/gradient-list";

interface IPageTop {
  id?: string;
  className?: string;
  gradient?: keyof IGradients;
  backgroundImage?: string;
  overlayColor?: string;
  borderTop?: string;
  backgroundColor?: string;
  parallax?: boolean;
  maxWidth?: number;
  maxHeight?: number;
  height?: "auto" | "tiny" | "small" | "medium" | "large";
  minHeight?: number;
  children: React.ReactNode;
  style?: CSSProperties;
  widthType?:
    | "FullWidthWrapper"
    | "FullWidthColumnWrapper"
    | "ColumnWidthWrapper"
    | "HalfColumnWidthWrapper";
}

interface IPageTopContainer {
  gradient?: keyof IGradients;
  maxHeight?: string;
  aspectRatios: IAspectRatios;
  backgroundColor?: string;
  borderTop?: string;
  backgroundImage?: string;
}

interface IAspectRatios {
  320: string;
  375: string;
  480: string;
  568: string;
  600: string;
  768: string;
  960: string;
  1024: string;
  1280: string;
  1440: string;
  1920: string;
}

interface IColorOverlay {
  overlayColor?: string;
}

const PageTopContainer = styled<IPageTopContainer, "div">("div")`
  transition: 0.2s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: ${props => props.aspectRatios[ 320 ]};
  /* padding-top: ${props => (!props.backgroundImage && "10rem;") || "66%"}; */
  /* padding-bottom: ${props =>
    (!props.backgroundImage && "4.8rem;") || "0"}; */
  width: 100%;
  border-top: ${props =>
    props.borderTop ? `0.2rem solid ${props.borderTop}` : ""};
  background-color: ${props => props.backgroundColor};
  background: ${props =>
    props.backgroundImage && `url(${props.backgroundImage})`};
  background-size: ${props => props.backgroundImage && "cover"};
  background-position: ${props => props.backgroundImage && "center center"};
  background: ${props =>
    props.gradient && generator({
      gradient: props.gradient,
      angle: -38
    })};

  .top-content-wrapper {
    transition: 0.2s ease-in-out;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-top: 6.4rem;
  }

  @media (min-width: 600px) {
    min-height: ${props => props.aspectRatios[ 600 ]};
  }

  @media (min-width: 768px) {
    .top-content-wrapper {
      padding-top: 7.2rem;
    }
  }

  @media (min-width: 960px) {
    min-height: ${props => props.aspectRatios[ 960 ]};
  }

  @media (min-width: 1280px) {
    min-height: auto;
    max-height: ${props => props.maxHeight};
    height: ${props => props.aspectRatios[ 1280 ]};
  }

  @media (min-width: 1440px) {
    height: ${props => props.aspectRatios[ 1440 ]};
  }
`;

const ColorOverlayWrapper = styled<IColorOverlay, "div">("div")`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.5;
  background: ${props => props.overlayColor};
`;

const ColorOverlay = (props: IColorOverlay) => {
  if (!props.overlayColor) {
    return null;
  }

  return <ColorOverlayWrapper overlayColor={props.overlayColor} />;
};

const PageTop = (props: IPageTop) => {
  const { height } = props;

  const aspectRatios: IAspectRatios = {
    320: "66.6vw",
    375: "66.6vw",
    480: "66.6vw",
    568: "66.6vw",
    600: "66.6vw",
    768: "66.6vw",
    960: "66.6vw",
    1024: "66.6vw",
    1280: "66.6vw",
    1440: "66.6vw",
    1920: "66.6vw"
  };

  let maxHeight = "58rem";

  switch (height) {
    case "large":
      maxHeight = "88rem";
      aspectRatios[ 600 ] = "72vw";
      aspectRatios[ 960 ] = "61vw";
      aspectRatios[ 1280 ] = "50vw";
      aspectRatios[ 1440 ] = "45.8vw";
      aspectRatios[ 1920 ] = "45.8vw";
      break;

    case "medium":
      maxHeight = "70rem";
      aspectRatios[ 600 ] = "64vw";
      aspectRatios[ 960 ] = "54vw";
      aspectRatios[ 1280 ] = "44vw";
      aspectRatios[ 1440 ] = "36.4vw";
      aspectRatios[ 1920 ] = "36.4vw";
      break;

    case "small":
      maxHeight = "58rem";
      aspectRatios[ 600 ] = "54vw";
      aspectRatios[ 960 ] = "46vw";
      aspectRatios[ 1280 ] = "38vw";
      aspectRatios[ 1440 ] = "34.8vw";
      aspectRatios[ 1920 ] = "30.2vw";
      break;

    default:
    case "tiny":
      maxHeight = "43.4rem";
      aspectRatios[ 600 ] = "50vw";
      aspectRatios[ 960 ] = "38vw";
      aspectRatios[ 1280 ] = "31vw";
      aspectRatios[ 1440 ] = "30.2vw";
      aspectRatios[ 1920 ] = "30.2vw";
      break;

    case "auto":
      maxHeight = "none";
      aspectRatios[ 600 ] = "auto";
      aspectRatios[ 960 ] = "auto";
      aspectRatios[ 1280 ] = "auto";
      aspectRatios[ 1440 ] = "auto";
      aspectRatios[ 1920 ] = "auto";
      break;
  }

  return (
    <PageTopContainer
      id={props.id}
      className="top-content-container"
      style={props.style}
      gradient={props.gradient}
      backgroundImage={props.backgroundImage}
      backgroundColor={props.backgroundColor}
      borderTop={props.borderTop}
      maxHeight={maxHeight}
      aspectRatios={aspectRatios}
    >
      <ColorOverlay overlayColor={props.overlayColor} />

      <div className="top-content-wrapper">
        {props.children}
      </div>
    </PageTopContainer>
  );
};

export default PageTop;
