import React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";

import checkeredOverlayImg from "../../assets/images/video-grid-overlay.png";
import styled from "../../theme/styled";

interface IHeroVideoWrapper {
  className: string;
  fallbackImage: string;
}

interface HeroVideoProps {
  /** Fallback image url */
  fallbackImage: string;
  /** A 16:9 aspect ratio video */
  src: string | ReactPlayerProps["url"];
  /** Whether to increase the contrast over the video */
  contrastGradient?: boolean;
  /** ReactPlayer props (overwrites our defaults set inside this file) */
  playerProps?: ReactPlayerProps;
  className?: string;
}

export const HeroVideo: React.FC<HeroVideoProps> = props => {
  const {
    src, fallbackImage, contrastGradient, playerProps = {}, className
  } = props;

  // Determine if the video is the space video which we want to align to the bottom right, the rest need to be centered
  const isSpecialVideo = src === "https://cdn.gravitywell.co.uk/videos/home-v2/braided_space.m3u8";

  return (
    <HeroVideoWrapper
      className={`hero-video-container ${className} ${isSpecialVideo ? "special-video" : ""}`}
      fallbackImage={fallbackImage}
    >
      <div className="overlay-img" />

      {contrastGradient && <div className="contrast-overlay" />}

      <ReactPlayer
        id="hero-video-player"
        pip={false}
        playing
        className="hero-video-player"
        width="100%"
        height="100%"
        loop
        url={src}
        controls={false}
        playsinline
        muted
        volume={0}
        {...playerProps}
      />
    </HeroVideoWrapper>
  );
};

export default HeroVideo;

const HeroVideoWrapper = styled<IHeroVideoWrapper, "div">("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.fallbackImage});
  background-size: cover;
  background-position: center;
  overflow: hidden;

  &.homepage {
    background-position: 100% 100%;
  }
  
  &.homepage video {
    object-fit: cover;
    object-position: center;
    filter: brightness(0.8);
  }

  &.hero-video-container.special-video video {
    object-position: 100% 100%;
  }

  .overlay-img {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${checkeredOverlayImg});
    background-repeat: repeat;
    opacity: 0.1;
    pointer-events: none;
  }

  .contrast-overlay {
    transition: 0.2s ease-in-out;
    display: none;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 50%
    );
    opacity: 0.5;
    pointer-events: none;
  }

  .hero-video-player {
    overflow: hidden;
    pointer-events: none;
    
    /* For 16:9 aspect ratios, this simulates "background cover and center" for the video */
    video {
      height: 100%;
      width: 177.77777778vh !important; /* 100 * 16 / 9 */
      min-width: 100%;
      min-height: 56.25vw; /* 100 * 9 / 16 */
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); 
    }
  }

  @media (min-width: 768px) {
    .contrast-overlay {
      display: block;
    }
  }

  @media (min-width: 1920px) {
    .contrast-overlay {
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 75%
      );
    }
  }
`;
